.footer__copyright {
    border-top: 1px solid #d8d8d8;
    max-width: 100%;
    padding-bottom: 40px;
}

.footer__logo {
    @include media-breakpoint-up(xs) {
        flex: 1 1 20%;
    }
}

.footer__logo .logo__link {
    @include media-breakpoint-up(sm) {
        text-align: left;
    }
}

.footer__icons {
    @include media-breakpoint-up(xs) {
        flex: 1 1 20%;
    }
}

.footer__icons .trust-icons__truste {
    @include media-breakpoint-up(sm) {
        display: block;
        margin-right: 0;
    }
}

.footer__menu {
    @include media-breakpoint-up(md) {
        margin-bottom: 40px;
    }
}
