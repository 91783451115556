.explore-lm__grid {
    display: -ms-grid; /* stylelint-disable-line value-no-vendor-prefix */
    display: grid;
    grid-column-gap: 0;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; /* stylelint-disable-line property-no-vendor-prefix */
    grid-row-gap: 0;
    grid-template-columns: repeat(12, 1fr);
    margin-top: 0.5rem;

    @media (max-width: 1430px) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

.explore-lm__title {
    @include font-with-fallback(montserrat-medium);

    color: #232222;
    font-size: 20px;
    font-weight: normal;
    grid-row: 1;
    margin: 0;
    max-width: calc(100% - 25px);

    @include media-breakpoint-down(sm) {
        grid-column: 1 / 13;
        grid-row: 1;
    }

    @include media-breakpoint-up(md) {
        grid-column: 1 / 9;
        grid-row: 1;
    }

    @include media-breakpoint-only(tn) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.explore-lm__overlay {
    color: $body-color;
    padding: 25px 0 30px;

    > .overlay__close {
        color: #bdc1c6;
        font-weight: bold;
        text-transform: lowercase;
    }
}

.explore-lm__categories {
    column-count: 3;
    column-gap: 0;
    padding-top: 10px;

    @include media-breakpoint-down(sm) {
        grid-column: 1 / 13;
        grid-row: 2;
    }

    @include media-breakpoint-up(md) {
        grid-column: 1 / 9;
        grid-row: 2;
    }
}

.explore-lm__categories-item {
    @include font-with-fallback(montserrat-semibold);

    color: #337ab7;
    font-size: 16px;
    font-weight: normal;
    line-height: 35px;

    a {
        color: inherit;
        text-decoration: none;
    }
}

.explore-lm__categories-link {
    color: #337ab7;
    text-decoration: none;

    &:hover {
        color: #23527c;
    }
}

.explore-lm__categories-link--pointer {
    cursor: pointer;
}

.explore-lm__categories-link.explore-other-categories {
    cursor: pointer;
}

.explore-lm__other {
    padding-top: 15px;

    @include media-breakpoint-down(sm) {
        grid-column: 1 / 13;
        grid-row: 4;
    }

    @include media-breakpoint-up(md) {
        grid-column: 9 / 13;
        grid-row: 2;
    }
}

.explore-lm__other-title {
    @include font-with-fallback(montserrat-medium);

    color: #232222;
    font-size: 18px;
    font-weight: normal;
    margin: 0 auto 0 0;
    text-align: left;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        grid-column: 1 / 13;
        grid-row: 3;
        padding-bottom: 10px;
        padding-top: 15px;
    }

    @include media-breakpoint-up(md) {
        grid-column: 9 / 13;
        grid-row: 1;
    }
}

.explore-lm__other-ddown {
    margin: 0 auto 10px 0;
    max-width: 404px;
    min-width: 320px;

    @include media-breakpoint-down(sm) {
        max-width: none;
        width: 100%;
    }
}

.explore-lm__other-ddown-content,
.explore-lm__other-ddown-content.dropdown-menu {
    @include font-with-fallback(montserrat-regular);

    background: $white;
    border-radius: 4px;
    color: #232222;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    margin-top: 2px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
}

.explore-lm__other-ddown-item,
.explore-lm__other-ddown-item.dropdown-item {
    @include font-with-fallback(montserrat-regular);

    color: #232222;
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 35px;
    padding: 0 20px;
    text-decoration: none;
    white-space: normal;

    &:hover,
    &:focus {
        background: #e8eaed;
        color: #232222;
    }
}

.explore-lm__other-ddown-title {
    @include font-with-fallback(montserrat-regular);

    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    color: #232222;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    height: 40px;
    max-width: 404px;
    overflow: hidden;
    padding: 8px;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 100%;
    }

    &:focus {
        outline: 0;
    }
}

.explore-lm__other-ddown .explore-lm__other-ddown-title {
    &::after {
        @include icon($fa-var-angle-down, 20px);

        background: #fff;
        color: #5f6368;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 5px;
        width: 20px;
    }
}

.explore-lm__other-ddown.show .explore-lm__other-ddown-title {
    &::after {
        @include icon($fa-var-angle-up, 20px);

        background: #fff;
        color: #5f6368;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 5px;
        width: 20px;
    }
}
