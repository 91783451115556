@function get-bp($key, $list: $grid-breakpoints) {
  @return map-get($list, $key);
}

@function get-bp-rem($key, $list: $grid-breakpoints) {
  @return px2rem(get-bp($key, $list));
}

@function px2rem($value) {
  $remSize: $value / 16px;
  @return #{$remSize}rem;
}

//For the usage of this mixin kindly refer to this: https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@mixin aspect-ratio($width, $height) {
	position: relative;

	> .content {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	&:before {
		display: block;
		content: "";
		padding-top: ($height / $width) * 100%;
		width: 100%;
	}
}

@mixin font-size($size-in-px) {
  font-size: $size-in-px;
  font-size: px2rem($size-in-px);
}

@mixin font-with-fallback($font-family, $size: null) {
  font-family: $font-family, $font-family-sans-serif;
  @if ($size) {
    @include font-size($size);
  }
}

@mixin icon($icon, $size: null) {
  content: $icon;
  font-family: FontAwesome;
  @if ($size) {
    font-size: $size;
  }
}

@mixin mobile-devices-landscape {
  @media screen and (max-width: 767px) and (orientation: landscape){
      @content;
  }
}

@mixin range-bp($min: null, $max: null, $screen: "all") {
  $range: "";
  @if ($min) {
    $range: "(min-width: #{$min})";
  }
  @if ($max) {
    @if ($min) {
      $range: "#{$range} and ";
    }
    $range: "#{$range}(max-width: #{$max})";
  }
  @if ($min or $max) {
    @media #{$screen} and #{$range} {
      @content;
    }
  }
}