.trust-icons {
    text-align: center;

    @include media-breakpoint-up(sm) {
        text-align: right;
    }
}

.trust-icons__truste {
    @include size(145px, 45px);

    display: block;
    margin: 0 auto 10px;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

.trust-icons__truste-link {
    @include size(145px, 45px);

    background-position: center center;
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto 10px;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

.trust-icons__truste img,
.trust-icons__truste-link img {
    width: 145px;
}

.trust-icons__bbb {
    @include size(150px, 68px);

    background: url("//seal-utah.bbb.org/logo/ruhzbum/bbb-90048270.png")
        no-repeat 0 0;
    display: block;
    margin: 0 auto 10px;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }

    &:hover {
        background-position: -150px 0;
    }
}

.trust-icons__bbb-link {
    @include size(150px, 68px);

    background-position: 0 0;
    background-repeat: no-repeat;
    display: block;
    margin: 0 auto 10px;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }

    &:hover {
        background-position: -150px 0;
    }
}
