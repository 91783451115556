.header__dropdown {
  position: relative;
}

.header__dropdown-button {
  @include font-with-fallback(Gotham-Medium, 16px);

  background: none;
  border-radius: 0;
  color: #032c6b;
  cursor: pointer;
  letter-spacing: normal;
  padding: 0;
  text-align: left;
  text-decoration: none;
  vertical-align: initial;

  &:hover {
    color: #032c6b !important;
    text-decoration: none;
  }

  @media all and (max-width: 479px) {
    @include font-size(14px);
  }
}

.header__dropdown-button--logged {
  padding-right: 20px;
}

.header__dropdown-list {
  border-bottom: 1px #e0e0e0 solid;
  border-right: none;
  display: block;
  font-size: 16px;
  padding: 0;
}

.header__dropdown-item {
  @include font-with-fallback(Gotham-Book, 14px);

  color: #000;
  margin-left: 0;
  margin-bottom: 0;
  padding: 5px 10px !important;
  text-decoration: none;
  width: 100%;

  &:hover {
    @include font-with-fallback(Gotham-Medium);

    background-color: #c4d6f7;
    color: #000;
  }

  &:last-child {
    border-bottom: none;
  }
}

.header__dropdown-menu {
  background-color: #FFF;
  border: 1px #747474 solid;
  border-radius: 0;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.33);
  left: -30px;
  margin-top: 10px;
  padding: 0;
}