.error404__a {
    @include font-with-fallback("Gotham-Medium", 14px);

    color: #337ab7;
    display: inline-block;
    padding: 15px 20px;
    text-decoration: none;
}

.error404__body {
    background: #e6e6e6;
    opacity: 1;
}

.error404__btn {
    background: #02397b;
    color: #fff;
    display: inline-block;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
}

.error404__btn:hover {
    background: #02397b;
    color: #fff;
    text-decoration: none;
}

.error404__content {
    background: #fff;
    padding: 40px 20px 200px;

    @include media-breakpoint-down(ss) {
        padding-bottom: 30px;
    }
}

.error404_form {
    display: flex;
    width: 100%;
}

.error404__form-container {
    margin: 0 auto;
    max-width: 450px;
}

.error404__form-button {
    background: #052264;
    border: 0;
    border-radius: 0 3px 3px 0;
    color: #fff;
    cursor: pointer;
    padding: 15px 20px;
    text-align: center;
}

.error404__form-button:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.error404__form-input {
    @include font-with-fallback("Gotham-Book", 14px);

    border: 1px solid #e6e6e6;
    border-radius: 3px 0 0 3px;
    padding: 15px 20px 15px 12px;
    width: 100%;
}

.error404__form-input:focus {
    outline: none;
}

.error404__general {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 14px 0 29px;
    padding: 0;

    @include media-breakpoint-up(sm) {
        margin: 24px 0 29px;
        width: 600px;
    }
}

.error404__general-body {
    column-count: 2;
    margin: 0 auto 30px;
    max-width: 430px;
    text-align: left;

    @include media-breakpoint-down(tn) {
        column-count: 1;
        max-width: 360px;
        padding: 0 15px;
    }
}

.error404__general-icon {
    height: 17px;
    margin-right: 17px;
    width: 15px;
}

.error404__general-link {
    @include font-with-fallback("Gotham-Medium", 13px);

    text-decoration: none;
}

.error404__general-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.error404__general-item {
    padding: 6px 0;
}

.error404__h1 {
    @include font-size(34px);

    color: #444;
    margin-bottom: 20px;
    margin-top: 0;
}

.error404__img-404 {
    @include media-breakpoint-down(tn) {
        width: 100%;
    }
}

.error404__p {
    @include font-size(14px);

    margin-bottom: 30px;
}
