.case-posted-count {
    display: inline-block;
    list-style: none;
    padding: 0;
    text-transform: uppercase;

    @include media-breakpoint-down(xs) {
        margin: 0;
        text-align: left;
    }
}

.case-posted-count__line-1 {
    @include font-with-fallback(gotham-bold, 17px);

    color: #7a7878;
    line-height: 17px;

    @include media-breakpoint-only(ss) {
        @include font-size(14px);
    }
}

.case-posted-count__line-2 {
    @include font-with-fallback(gotham-medium, 32px);

    color: #5f5e5e;
    line-height: 32px;

    @include media-breakpoint-only(ss) {
        @include font-size(26px);
    }
}

.case-posted-count__line-3 {
    @include font-with-fallback(gotham-medium, 17px);

    color: #7a7979;
    line-height: 17px;
    text-align: right;

    @include media-breakpoint-only(ss) {
        @include font-size(14px);
    }
}