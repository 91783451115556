.cta-button {
  @include font-with-fallback(Gotham-Medium, 14px);
  background: #f5b554;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .5);
  color: $blue-dark;
  cursor: pointer;
  font-weight: 700;
  display: block;
  margin: 30px auto;
  max-width: 260px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: $blue-dark;
    text-decoration: none;
  }
}