$c-main__body-padding-left: (
        tn: 10px,
        xs: 12px,
        sm: 15px,
        md: 20px,
        lg: 30px
);
$c-main__body-padding-right: (
        tn: 10px,
        xs: 12px,
        sm: 10px,
        md: 20px,
        lg: 30px
);
$c-main__sidebar-padding-left: (
        tn: 10px,
        xs: 12px,
        sm: 10px,
        md: 20px,
        lg: 30px
);
$c-main__sidebar-padding-right: (
        tn: 10px,
        xs: 12px,
        sm: 15px,
        md: 20px,
        lg: 30px
);

.main__sidebar {
  padding-bottom: 50px;
}

@each $bp, $value in $c-main__body-padding-left {
  @include media-breakpoint-up($bp) {
    .main__body {
      padding-left: $value;
      padding-right: map-get($c-main__body-padding-right, $bp);
    }
    .main__content {
      padding-left: map-get($c-main__sidebar-padding-left, $bp);
      padding-right: map-get($c-main__sidebar-padding-right, $bp);
    }
    .main__sidebar {
      padding-left: map-get($c-main__sidebar-padding-left, $bp);
      padding-right: map-get($c-main__sidebar-padding-right, $bp);
    }
  }
}

@include media-breakpoint-down(tn) {
  body{
    min-width: auto;
  }

  .container {
    max-width: none;
  }
}