@import "bs4/root";

@import "@bootstrap/scss/modal";
@import "@bootstrap/scss/dropdown";
@import "bs4/override";

@import "common/montserrat";
@import "@poly-fluid-sizing";

//BASE
@import "bs4/overlay/base";
@import "bs4/explore-lm/base";
@import "bs4/cta-button/base";
@import "bs4/modal-generic/base";
@import "bs4/modal-iframe/base";
@import "bs4/main/base";
@import "bs4/other-categories/base";
@import "bs4/service-menu/base";
@import "bs4/service-menu/home";
@import "bs4/trust-icons/base";

@import "bem/sticky-header/lawlib";
@import "bs4/mobile-menu/base";

@import "bem/header/dropdown-shared";
@import "bs4/copyright/base";
@import "bs4/copyright/home";
@import "bs4/case-posted-count/base";
@import "bs4/footer/home";
@import "bs4/footer/404-page";
@import "bs4/footer-secondary/base";
@import "bs4/footer-secondary/404-page";
@import "bs4/footer-tertiary/base";
@import "bs4/footer-tertiary/404-page";
@import "bs4/social/base";

@import "page-not-found-404/main-v3";

html,
body {
    @include font-with-fallback(Gotham-Book, 16px);
}
