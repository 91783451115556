.mobile-menu {
    bottom: 0;
    height: 0;
    left: 0;
    position: absolute;
    width: 100%;

    a {
        text-decoration: none;
    }
}

.mobile-menu__accord-header {
    @include font-with-fallback(montserrat-medium);

    color: #232222;
    cursor: pointer;
    display: inline-block;
    line-height: 40px;
    text-transform: uppercase;
}

.mobile-menu__collapse {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height, overflow 0.5s, 0.1s ease-in;
}

.mobile-menu__collapse.is-expanded {
    max-height: 700px;
    overflow: visible;
}

.mobile-menu__collapse-attorney-item {
    @include font-size(14px);

    color: #337ab7;
}

.mobile-menu__collapse-cont {
    padding: 10px 0;
}

.mobile-menu__collapse-explore-legalmatch-header {
    @include font-with-fallback(montserrat-medium, 18px);

    color: #232222;
    font-weight: normal;
    margin: 0;
    padding: 5px 0 0;
}

.mobile-menu__collapse-explore-legalmatch-header--underline {
    text-decoration: underline;
}

.mobile-menu__collapse-explore-legalmatch-header--strong {
    @include font-with-fallback(montserrat-semibold);

    font-weight: normal;

    @include media-breakpoint-between(null, xs) {
        display: block;
    }
}

.mobile-menu__collapse-explore-legalmatch-item {
    margin-bottom: 15px;
    position: relative;
}

.mobile-menu__column {
    display: flex;
    flex: 1 0 100%;

    @include media-breakpoint-between(null, xs) {
        flex-wrap: wrap;
    }
}

.mobile-menu__elmo-container .mobile-menu__elmo-dropdown::after {
    @include icon($fa-var-angle-down, 22px);

    color: #5f6368;
    font-weight: bolder;
    position: absolute;
    right: 15px;
    top: 7px;
}

.mobile-menu__elmo-container.show .mobile-menu__elmo-dropdown::after {
    @include icon($fa-var-angle-up, 22px);

    color: #5f6368;
    font-weight: bolder;
    position: absolute;
    right: 15px;
    top: 7px;
}

.mobile-menu__left-col {
    padding: 10px;
    width: 45%;

    @include media-breakpoint-between(null, xs) {
        margin-bottom: 0;
        width: 100%;
    }
}

.mobile-menu__elmo-dropdown {
    @include font-size(14px);

    background: #fff;
    border: 1px solid #dadce0;
    border-radius: 4px;
    color: #232222;
    cursor: pointer;
    line-height: 1;
    padding: 10px 5px;
    text-align: left;
    width: 100%;

    @include media-breakpoint-between(null, tn) {
        margin: 0;
        max-width: none;
    }

    &::after {
        clear: both;
        color: #5f6368;
        content: " ";
        display: block;
    }
}

.mobile-menu__elmo-dropdown-caret {
    float: right;
}

.mobile-menu__elmo-dropdown-menu {
    @include font-size(14px);

    background: #fff;
    margin: 2px 0 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;

    @include media-breakpoint-between(null, tn) {
        max-width: none;
        width: 100%;

        > li > a {
            padding: 8px 5px;
            white-space: normal;
            word-wrap: break-word;
        }
    }

    > li > a {
        color: #232222;
        display: block;
        padding: 8px 5px;
    }
}

.mobile-menu__elmo-category-links {
    @include media-breakpoint-between(xs, sm) {
        column-count: 2;
        column-gap: 20px;
    }

    @include media-breakpoint-between(null, tn) {
        column-count: 1;
    }
}

.mobile-menu__elmo-category-links-item {
    @include font-with-fallback(montserrat-regular, 15px);

    color: #337ab7 !important; // override
    display: inline-block;
    padding-bottom: 7px;
    padding-top: 7px;
    text-transform: capitalize;
    width: 100%;

    @include media-breakpoint-between(null, sm) {
        display: block;
    }
}

.mobile-menu__elmo-category-links-item--pointer {
    cursor: pointer;
}

.mobile-menu__header-link {
    display: inline-block;
    line-height: 40px;
    text-transform: uppercase;

    &:active,
    &:hover {
        text-decoration: none;
    }
}

.mobile-menu__icon {
    @include font-size(20px);

    color: #232222;
    display: inline-block;
    height: 23px;
    margin: 0 6px 0 1px;
    text-align: center;
    width: 23px;
}

.mobile-menu__icon-balance-scale {
    background-image: url("/bd/homepage-redesign/images/svg/balance-scale-solid.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 22px;
    position: relative;
    top: 4px;

    &::before {
        content: "" !important;
    }
}

.mobile-menu__icon-user-friends {
    background-image: url("/bd/homepage-redesign/images/svg/user-friends-solid.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 22px;
    position: relative;
    top: 4px;

    &::before {
        content: "";
    }
}

.mobile-menu__link {
    display: block;
    line-height: 25px;
    padding: 5px;
    text-transform: capitalize;
}

.mobile-menu__login-cont {
    display: flex;
    margin-bottom: 20px;

    @include media-breakpoint-between(null, ss) {
        flex-wrap: wrap;

        &-left,
        &-right {
            flex: 1 0 50%;
        }
    }

    &-left {
        flex: 1 0 40%;
    }

    &-right {
        flex: 1 0 60%;
    }
}

.mobile-menu__lm-homepage,
.mobile-menu__lm-accordion {
    border-top: 1px solid #dbdbdb;
}

.mobile-menu__main {
    @include font-with-fallback(montserrat-medium);

    background: #fff;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.mobile-menu__main-list {
    list-style: none;
    margin: 0;
    padding: 0 10px;
    width: 100%;
}

.mobile-menu__main-list-item {
    min-height: 40px;
    padding: 3px;
}

.mobile-menu__main-list-item.js-open .mobile-menu__collapse {
    max-height: none;
    overflow: visible;
}

.mobile-menu__main-list-item.js-open .js-menu-icon {
    &::before {
        content: "\f107";
        font-weight: bolder;
    }
}

.mobile-menu__main-list-item .js-menu-icon {
    &::before {
        content: "\f105";
        font-weight: bolder;
    }
}

.mobile-menu__right-col {
    padding: 10px;
    width: 55%;

    @include media-breakpoint-between(null, ss) {
        width: 100%;
    }
}

.mobile-menu__overlay {
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 0;
}

.mobile-menu__search {
    padding: 10px 10px 0;
}

.mobile-menu__search-form-cont {
    display: flex;
    flex-direction: row;
    height: 37px;
    margin-left: 2px;
    overflow: hidden;
}

.mobile-menu__search-input {
    @include font-size(17px);

    border: 1px solid #bab6b6;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    flex: 1 100%;
    height: 37px;
    padding: 0 15px;
}

.mobile-menu__search-submit {
    @include font-size(23px);

    background: transparent;
    border: 0;
    color: #9b9797;
    cursor: pointer;
    min-height: 37px;
    text-align: center;
    width: 46px;

    &:disabled {
        cursor: not-allowed;
    }

    &:focus {
        outline: none;
    }
}
