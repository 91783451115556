.footer-secondary {
    background: #e3e3e3;
}

.footer-secondary__body {
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 15px 0;

    @include media-breakpoint-down(xs) {
        min-height: 104px;
        position: relative;
        z-index: 0;
    }
}

.footer-secondary__item {
    align-self: center;
    justify-self: center;
    transition: opacity 0.4s ease;

    @include media-breakpoint-only(ss) {
        width: 150px;
    }

    @include media-breakpoint-only(sm) {
        margin: 0 10px;
    }

    @include media-breakpoint-down(xs) {
        left: 0;
        margin: 0;
        padding: 20px 0 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 1;

        &:not(.is-active){
            opacity: 0;
        }
    }
}

.footer-secondary__item-img {
    max-height: 68px;
    max-width: 180px;
}

.footer-secondary__item-img--bbb{
    max-width: 150px;
    
    @include media-breakpoint-down(xs) {
        margin-top: 5px;
    }
}