.overlay {
    background: $white;
    border-radius: 5px;
    display: none;
    left: 0;
    position: absolute;
    top: 66px;
    width: 100%;
    z-index: 99;
}

.overlay.is-visible {
    display: block;
}

.overlay__toggle {
    cursor: pointer;
}

.overlay__arrow {
    left: 0;
    position: absolute;
    top: 0;

    &::after {
        background: $white;
        border-color: #bbb;
        border-style: solid;
        border-width: 0 0 1px 1px;
        content: "";
        float: right;
        font-size: 0;
        height: 20px;
        line-height: 0;
        margin-right: -20px;
        margin-top: -11px;
        transform: rotate(135deg);
        width: 20px;
    }

    &::before {
        border-bottom: 14px solid $white;
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        content: "";
        float: right;
        height: 0;
        margin-right: -25px;
        margin-top: -14px;
        width: 0;
    }
}

.overlay__close {
    @include font-with-fallback(Gotham-Book, 30px);

    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
}

.overlay__backdrop {
    background: rgba(0, 0, 0, 0.3);
    display: none;
    left: 0;
    min-height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
}

.overlay__backdrop.is-visible {
    display: block;
}
