$montserrat-font-path: "/css/fonts/montserrat/";
$montserrat-font-list: "Black", "BlackItalic", "Bold", "BoldItalic", "ExtraBold", "ExtraBoldItalic", "ExtraLight", "ExtraLightItalic", "Italic", "Light", "LightItalic", "Medium", "MediumItalic", "Regular", "SemiBold", "SemiBoldItalic", "Thin", "ThinItalic";

@mixin font-face($name, $source) {
  @font-face {
    font-family: quote($name);
    src: url(quote(#{$source + '.woff2' })) format("woff2"),
    url(quote(#{$source + '.woff' })) format("woff");
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
}

@each $name in $montserrat-font-list {
  $montserrat-font: #{"Montserrat-" + $name};
  @include font-face($montserrat-font, #{$montserrat-font-path + "subset-" + $montserrat-font});
}
