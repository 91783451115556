// iOS smooth scroll
.modal {
  -webkit-overflow-scrolling: touch;
  @include media-breakpoint-down(md) {
    padding-left: 0;
  }
}

.modal-lg {
  @include media-breakpoint-down(md) {
    margin-left: auto;
    margin-right: auto;
    max-width: unset;
    width: 98%;
  }
}

@include media-breakpoint-between(xs, ss) {
  .container {
    max-width: unset;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
  }
}
