.footer-tertiary {
    background: #363636;
}

.footer-tertiary__body {
    align-content: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 10% 100px;

    @include media-breakpoint-down(xs) {
        display: block;
        padding-bottom: 20px;
    }

    @include media-breakpoint-down(ss) {
        padding-bottom: 24px;
        padding-left: 8%;
        padding-right: 8%;
    }

    @include media-breakpoint-only(sm) {
        margin: 0 auto;
        padding-bottom: 24px;
        padding-left: 8%;
        padding-right: 8%;
    }
}

.footer-tertiary__btn {
    display: inline-block;
    margin-right: 10px;

    @include media-breakpoint-down(tn) {
        margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
        display: block;
        margin-right: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.footer-tertiary__btns {
    list-style: none;
    margin: 15px 0;
    padding: 0;
}

.footer-tertiary__header {
    @include font-size(15px);

    color: #fff;
    text-transform: uppercase;

    &--normal {
        @include font-size(14px);

        text-transform: none;
    }
}

.footer-tertiary__item {
    @include media-breakpoint-down(ss) {
        margin: 0 auto;
        text-align: center;
    }

    @include media-breakpoint-down(sm) {
        width: 200px;
    }

    &--width-240 {
        @include media-breakpoint-down(sm) {
            width: 240px;
        }
    }
}

.footer-tertiary__link {
    @include font-with-fallback(gotham-medium, 13px);

    background: #ececec;
    border: 1px solid #7a7a7a;
    border-radius: 3px;
    color: #232222;
    line-height: 28px;
    padding: 6px 12px;
    text-decoration: none;
    text-transform: uppercase;

    @include media-breakpoint-down(tn) {
        @include font-size(11px);
    }

    @include media-breakpoint-down(sm) {
        display: block;
        line-height: 16px;
        margin-bottom: 7px;
        text-align: center;
    }

    &:hover {
        color: #232222;
        text-decoration: none;
    }
}
