@mixin service-menu-fluid-item-property($prop) {
    @include poly-fluid-sizing($prop, (get-bp(xs): 15px, get-bp(sm): 5px, get-bp(md): 8px, get-bp(lg): 15px)); 
}

.service-menu {
    margin: 0;
    padding: 0;
    text-align: center;
}

.service-menu__item {
    @include font-with-fallback(gotham-book, 14px);

    display: inline-block;
    list-style: none;

    &::before {
        @include icon($fa-var-circle, 6px);
    }

    @include media-breakpoint-down(tn) {
        display: inline-block;
        padding-bottom: 5px;
        padding-left: 0;
        padding-right: 0;
    }

    &:first-child {

        &::before {
            content: none;
        }

        @include media-breakpoint-down(tn) {
            display: block;
        }
    }

    &:not(:first-child) {
        &::before {
            @include icon($fa-var-circle, 8px);
            @include service-menu-fluid-item-property('margin-right');

            position: relative;
            top: -2px;

            @include media-breakpoint-down(tn) {
                display: none;
            }
        }
    }

    &:nth-child(2) {
        @include service-menu-fluid-item-property('padding-left');
        @include service-menu-fluid-item-property('padding-right');

        &::before {
            @include media-breakpoint-down(sm) {
                @include icon($fa-var-circle);
            }
        }

        @include media-breakpoint-down(tn) {
            padding-left: 0;
            padding-right: 10px;

            &::before {
                display: none;
            }
        }
    }
}

.service-menu__link {
    color: #97a8a7;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}