.copyright {
    @include font-with-fallback(Gotham-Book, 12px);

    color: #97a0a7;
    line-height: 1;
    padding: 0 15px 45px 15px;
    text-align: center;
}

.copyright__sign {
    @include font-size(24px);

    line-height: px2rem(22px);
    vertical-align: middle;
}