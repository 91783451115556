.modal-generic__close {
    @include font-with-fallback(montserrat-regular);

    background: transparent;
    border: 0;
    color: #000;
    cursor: pointer;
    float: right;
    font-size: 21px;
    font-weight: 700;
    opacity: 0.2;
    text-shadow: 0 1px 0 #fff;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.modal-generic__close--position-absolute {
    position: absolute;
    right: 10px;
    top: 10px;
}

.modal-generic__dialog {
    @include media-breakpoint-up(sm) {
        margin-top: 90px;
    }
}

.modal-generic__title {
    @include font-with-fallback("Gotham-Medium", 14px);

    font-weight: normal;
    margin: 0 20px 20px 0;
}
